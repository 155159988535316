<template>  
<!-- Footer 2 - Bootstrap Brain Component -->
<footer class="footer">

<!-- Widgets - Bootstrap Brain Component -->
<section class="bg-champ py-4 py-md-5 py-xl-8 border-top border-light">
  <div class="container overflow-hidden">
    <div class="row gy-4 gy-lg-0 justify-content-xl-between">
      <div class="col-12 col-md-4 col-lg-3 col-xl-2">
        <div class="widget">
          <a href="#!">
            <img src="@/assets/logo-rad.png" alt="BootstrapBrain Logo" width="200">
          </a>
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg-3 col-xl-2">
        <div class="widget">
          <h4 class="widget-title mb-4">Chennai</h4>
          <address class="mb-4">711 PH Road, Opp.Anna Arch, NSK Nagar, Arumbakkam, Chennai-600106.</address>
          
          
         
          <p class="mb-1">
            <a class="link-secondary text-decoration-none" href="tel:+91 98403 98093.">(+91) 98403 98093.</a>
          </p>
          <p class="mb-0">
            <a class="link-secondary text-decoration-none" href="mailto:  r.rajaboopathy@gmail.com">  r.rajaboopathy@gmail.com</a>
          </p>
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg-3 col-xl-2">
        <div class="widget">
          <h4 class="widget-title mb-4">Madurai</h4>
          <address class="mb-4">KOVALAN NAGAR 4th Street, Near TVS Matriculation School, Near Gowri Mariamman Kovil, Palanganatham, Madurai-625003</address>
           </div>
      </div>
      <div class="col-12 col-md-4 col-lg-3 col-xl-2">
        <div class="widget">
          <h4 class="widget-title mb-4">Salem</h4>
          <address class="mb-4">Minerva upstairs, 2nd Floor, Udayappa Colony, Maravaneri,  Salem - 636007</address>
           </div>
      </div>      
        </div>
      </div>
   
</section>


<div class="bg-light py-4 py-md-5 py-xl-8 border-top border-light-subtle">
  <div class="container overflow-hidden">
    <div class="row gy-4 gy-md-0 align-items-md-center">
      <div class="col-xs-12 col-md-7 order-1 order-md-0">
        <div class="copyright text-center text-md-start">
          &copy; 2024. All Rights Reserved.
        </div>
        <div class="credits text-secondary text-center text-md-start mt-2 fs-8">
          Built by <a href="https://wa.link/5ah995" class="link-secondary text-decoration-none">Prajna Soft Creations</a>
        </div>
      </div>

      <div class="col-xs-12 col-md-5 order-0 order-md-1">
        <nav class="navbar navbar-expand-lg bg-header mb-0 shadow">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 p-3 mx-auto">            
            
            <li class="nav-item px-2">
              <a class="brand-icons" href="https://www.facebook.com/radianiasacademy" ><font-awesome-icon :icon="['fab', 'facebook']" /></a>
            </li>
            <li class="nav-item px-2">
              <a class="brand-icons" href="https://www.instagram.com/radian_ias_academy_rb/" ><font-awesome-icon :icon="['fab', 'instagram']" /></a>
            </li>
            <li class="nav-item px-2">
              <a class="brand-icons" href="https://www.threads.net/@radian_ias_academy_rb" ><font-awesome-icon :icon="['fab', 'threads']" /></a>
            </li>
            <li class="nav-item px-2">
              <a class="brand-icons" href="https://t.me/radianiasacademy"><font-awesome-icon :icon="['fab', 'telegram']" /></a>
            </li>
            <li class="nav-item px-2">
              <a class="brand-icons" href="https://wa.link/6e7cig" ><font-awesome-icon :icon="['fab', 'whatsapp']" /></a>
            </li>
            <li class="nav-item px-2">
              <a class="brand-icons" href="https://www.youtube.com/@rrajaboopathy" ><font-awesome-icon :icon="['fab', 'youtube']" /></a>
            </li>
            <li class="nav-item px-2">
              <a class="brand-icons" href="https://x.com/psc_exams" ><font-awesome-icon :icon="['fab', 'x-twitter']" /></a>
            </li>
                           
          </ul>
        </nav>
        </div>
        </div>
     
      </div>
    </div>
 

</footer>

  
</template>
<script>
export default {
  name: 'FooterComp',  
}
</script>