<template>   
    <nav class="navbar navbar-expand-lg bg-header mb-0 shadow">
      <div class="container-fluid">
        
        <img class = "mx-auto" src ="@/assets/logo-rad.png" width ="70">
        <div class = "row ">
        <a class="brand-head" href="https://radianiasacademy.in/">ரேடியன் ஐஏஎஸ் அகாடமி</a>
        <p class="text-white">சென்னை | சேலம் | மதுரை</p>
        </div>
        
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>       
       
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 p-3 mx-auto">            
            
            <li class="nav-item px-2">
              <a class="brand-icons" href="https://www.facebook.com/radianiasacademy" ><font-awesome-icon :icon="['fab', 'facebook']" /></a>
            </li>
            <li class="nav-item px-2">
              <a class="brand-icons" href="https://www.instagram.com/radian_ias_academy_rb/" ><font-awesome-icon :icon="['fab', 'instagram']" /></a>
            </li>
            <li class="nav-item px-2">
              <a class="brand-icons" href="https://www.threads.net/@radian_ias_academy_rb" ><font-awesome-icon :icon="['fab', 'threads']" /></a>
            </li>
            <li class="nav-item px-2">
              <a class="brand-icons" href="https://t.me/radianiasacademy"><font-awesome-icon :icon="['fab', 'telegram']" /></a>
            </li>
            <li class="nav-item px-2">
              <a class="brand-icons" href="https://wa.link/6e7cig" ><font-awesome-icon :icon="['fab', 'whatsapp']" /></a>
            </li>
            <li class="nav-item px-2">
              <a class="brand-icons" href="https://www.youtube.com/@rrajaboopathy" ><font-awesome-icon :icon="['fab', 'youtube']" /></a>
            </li>
            <li class="nav-item px-2">
              <a class="brand-icons" href="https://x.com/psc_exams" ><font-awesome-icon :icon="['fab', 'x-twitter']" /></a>
            </li>
                           
          </ul>
         
        </div>
       </div>
       </nav>
</template>


<script>
export default {
  name: 'NavBar',
  props: {
    msg: String
  }
}
</script>



<style>
   .brand-head {
      font-family: "Noto Sans Tamil", sans-serif;
      font-optical-sizing: auto;
      font-weight: 600;
      font-style: normal;
      color: white;
      text-decoration: none;
      font-size: x-large;
      }
      .brand-icons {
      font-family: "Noto Sans Tamil", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      color: white;
      text-decoration: none;
      font-size: x-large;
      }
      .brand-link {
      font-family: "Noto Sans Tamil", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      color: white;
      text-decoration: none;
      font-size: small;
      }
      .brand-head:hover{
        color:#EFD3B5;
      }
      .brand-link:hover{
        color:#EFD3B5;
      }
      .bg-header{
        background-color: #5F093D;
      }
    </style>