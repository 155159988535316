<template> 
  <TopBar />
  <NavBar /> 
  <BannerMain />
  <FooterComp />
</template>

<script>
import NavBar from './components/NavBar.vue';
import TopBar from './components/TopBar.vue';
import BannerMain from './components/BannerMain.vue'
import FooterComp from './components/footer.vue'

export default {
  name: 'App',
  components: {
    TopBar,
    NavBar,
    BannerMain,
    FooterComp,
   
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
