<template>
    <nav class="navbar navbar-light top-bar">
        <div class = "container">
            <a class="brand-link" href ="tel:+91 98403 98093"><font-awesome-icon :icon="['fas', 'phone']" /> &nbsp; +91 98403 98093</a>
            <a class="brand-link" href ="mailto:r.rajaboopathy@gmail.com"><font-awesome-icon :icon="['fas', 'envelope']" /> &nbsp; r.rajaboopathy@gmail.com</a>
            <a class="brand-link" ><font-awesome-icon :icon="['fas', 'location-dot']" />&nbsp;  711 PH Road, Opp.Anna Arch, NSK Nagar, Arumbakkam, Chennai-600106.</a>
        </div> 
             
           
</nav>
</template>
<style>
.top-bar {
        background-color: #B21368;
      }

</style>

