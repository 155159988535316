<template>
    <header class="bg-champ py-5">
    <div class="container px-5">
        <div class="row gx-5 align-items-center justify-content-center">
            <div class="col-lg-8 col-xl-7 col-xxl-6">
                <div class="my-5 text-center text-xl-start ">
                    <h6 class="tamil-font-dark-sm typingEffect">குரூப் II,II-A அறிவிக்க வெளியீடு</h6>
                    <h2 class="fw-bolder banner-head mb-2">தமிழ்நாடு அரசு பணியாளர் தேர்வாணையம் தேர்வு பயிற்சி</h2>
                   
                    
                    <p class="lead fw-normal tamil-font-dark-sm mb-4">ரேடியன் மாணவர்கள் 4000-த்திற்கும் மேற்பட்டவர்கள் IAS, IPS, துணை ஆட்சியர்கள், DSP, நகராட்சி ஆணையர்கள், சார் பதிவாளர்கள், DEO, குரூப்-2A, குரூப்-4, வி.ஏ.ஒ., POLICE, SI, ஆசிரியர்கள், வங்கிப்பணியில் உள்ளனர்.</p>
                    <div class="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                        <a class="btn-main p-4 rounded-3 tamil-font-light-sm" href="#features">பதிவு செய்ய</a>
                        
                    </div>
                </div>
            </div>
            <div class="col-xl-5 col-xxl-6 d-none d-xl-block text-left"><img class="img-fluid rounded-3 mb-2" src="@/assets/bg.jpg" alt="..." /> <h6> Mr. R. Rajaboopathi, Founder, Radian IAS Academy, receiving gold medal from former President of India <br>Dr. APJ Abdul Kalam.</h6></div>
            
        </div>
    </div>
</header>
<section class="py-5" id="features">
                <div class="container px-5 my-5">
                    <div class="row gx-5">
                        <div class="col-lg-4 mb-5 mb-lg-0 tamil-font-dark-sm"><h2 class="fw-bolder mb-0">2010 முதல் கடந்த 14 வருடங்களாக LIVE & RECORDED ஆன்லைன் வகுப்புகளும் உண்டு.</h2></div>
                        <div class="col-lg-8">
                            <div class="row gx-5 row-cols-1 row-cols-md-2">
                                <div class="col mb-5 h-100">
                                    <div class="feature bg-header bg-gradient text-white rounded-3 mb-3 h1"><font-awesome-icon :icon="['fas', 'video']" /></div>
                                    <h3>Online Classes</h3>
                                    <p class="mb-0 tamil-font-dark-sm">நேரடி வகுப்பறை வகுப்புகள், ஆன்லைன்  வகுப்புகள் உண்டு</p>
                                </div>
                                <div class="col mb-5 h-100">
                                    <div class="feature bg-header bg-gradient text-white rounded-3 mb-3 h1"><font-awesome-icon :icon="['fas', 'indian-rupee-sign']" /></div>
                                    <h3>Installments</h3>
                                    <p class="mb-0 tamil-font-dark-sm">2 அல்லது 3 தவணைகள் மூலமாகவும் பணம் செலுத்தலாம்.</p>
                                </div>
                                <div class="col mb-5 mb-md-0 h-100">
                                    <div class="feature bg-header bg-gradient text-white rounded-3 mb-3 h1"><font-awesome-icon :icon="['fas', 'people-roof']" /></div>
                                    <h3>Free Introductory Session</h3>
                                    <p class="mb-0 tamil-font-dark-sm">இலவச அறிமுக நேரடி வகுப்பறை மற்றும் ஆன்லைன் வகுப்புகள்  &nbsp; <br><a href="https://wa.link/6e7cig">சேர கிளிக் செய்யவும்</a></p>
                                </div>
                                <div class="col h-100">
                                    <div class="feature bg-header bg-gradient text-white rounded-3 mb-3 h1"><font-awesome-icon :icon="['fas', 'user-injured']" /></div>
                                    <h3>Fee Discount</h3>
                                    <p class="mb-0 tamil-font-dark-sm"> மாற்றுத்திறனாளிகள், ரேடியன் மாணவர்கள், ஆதரவற்ற கைம்பெண்கள், பழங்குடியினர் மற்றும் ஏழை மாணவர்களுக்கு FEE DISCOUNT உண்டு.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
<section class="py-5 bg-champ" id="fee-details">
    <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
      <h1 class="tamil-font-dark-sm">வகுப்புகள்</h1>
      <p class="lead tamil-font-dark-sm">குறித்து வேறு சந்தேகம் இருந்தால் whatsapp செய்தி அனுப்ப <a href="https://wa.link/6e7cig">கிளிக் செய்யவும்</a></p>
    </div>
    <h4><u>GROUP-1-2-DEO-AC-ACL-ACF-ETC-PRELIMS</u></h4>
  <div class="container p-3">
  <div class="row">
    <div class="col-sm">
      <div class="card">
        <img class="card-img-top" src="@/assets/onlineclass.svg" alt="Card image cap">
  <div class="card-body">
    <h5 class="card-title">Online Classes</h5>
    <h5 class="card-title tamil-font-dark-sm">ஆன்லைன வகுப்பு</h5>
    <p class="card-text">Online both live & recorded classes with printed materials free (code AT1L1)</p>
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item"><font-awesome-icon :icon="['fas', 'indian-rupee-sign']" /><s>30,200</s> 16,200 Only</li>
    <li class="list-group-item tamil-font-dark-sm">2 தவணைகள் ரூ 8,500 அல்லது 3 தவணைகள் ரூ 5,500 மூலம் செலுத்தலாம்</li>
    <li class="list-group-item">Printed materials can be collected in person on full payment.</li>
  </ul>
  <div class="card-body">
    <iframe src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fradianiasacademy%2F&width=180&layout&action&size&share=true&height=35&appId" width="180" height="35" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
  </div>
</div>
    </div>
    <div class="col-sm">
      <div class="card">
        <img class="card-img-top" src="@/assets/classroom.svg" alt="Card image cap">
  <div class="card-body">
    <h5 class="card-title">Classroom Coaching</h5>
    <h5 class="card-title tamil-font-dark-sm">நேரடி வகுப்பறை வகுப்பு</h5>
    <p class="card-text">Direct class room classes with printed materials free (code AT1L2) .</p>
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item"><font-awesome-icon :icon="['fas', 'indian-rupee-sign']" /><s>40,200</s> 20,200 Only</li>
    <li class="list-group-item tamil-font-dark-sm">2 தவணைகள் ரூ 10,200  மூலம் செலுத்தலாம்</li>
    <li class="list-group-item">Printed materials can be collected in person on full payment.</li>
  </ul>
  <div class="card-body">
    <iframe src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fradianiasacademy%2F&width=180&layout&action&size&share=true&height=35&appId" width="180" height="35" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
  </div>
</div>
    </div>
    <div class="col-sm">
      <div class="card">
        <img class="card-img-top" src="@/assets/postal.svg" alt="Card image cap">
  <div class="card-body">
    <h5 class="card-title">Postal Materials</h5>
    <p class="card-text">Postal study materials, postage charges extra with PDF test materials Test batches are available till exams.<span class="tamil-font-dark-sm">இடையில் சேர்ந்தாலும் நடந்த அனைத்து தேர்வுகளும் கிடைக்கும்.

</span></p>
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item"><font-awesome-icon :icon="['fas', 'indian-rupee-sign']" />6,200 Only</li>
   
  </ul>
  <div class="card-body">
    <iframe src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fradianiasacademy%2F&width=180&layout&action&size&share=true&height=35&appId" width="180" height="35" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
  </div>
</div>
    </div>
  </div>
</div>
    
</section>

  <section class="py-5">
    
                <div class="container px-5 my-5">
                    <div class="row gx-5 justify-content-center">
                        <div class="col-lg-8 col-xl-6">
                            <div class="text-center">
                                <h2 class="fw-bolder">Radian Books</h2>
                                <p class="lead fw-normal text-muted mb-5">Buy Radian Books from Amazon.</p>
                                <h1><a href="https://www.amazon.in/GENERAL-ENGLISH-RADIANs-TNPSC-BOOK/dp/B07L6HN3GH/ref=sr_1_1?dib=eyJ2IjoiMSJ9.RdB5ZiCxySW6AmXtI1IsAsm9pT8fVVu2SPk2EbHYZezIDo9ZPRPOB8Aggh7DNc_rSrWP0166Ji21AV1x1oRNgeAP6F8yfJGh-XGjBQPyg7g.-bCAAfj5pSTEdiG1puJgtbhGNZfevcITYZ1L_p5GSj4&dib_tag=se&qid=1719307684&refinements=p_27%3ARajaboopathy+R&s=books&sr=1-1" target="_blank"><font-awesome-icon :icon="['fab', 'amazon']" /></a></h1>
                            </div>
                        </div>
                    </div>
                    <div class="row gx-5">
                        <div class="col-lg-4 mb-5">
                            <div class="card h-100 shadow border-0">
                                <img class="card-img-top" src="@/assets/book-1.jpg" alt="..." />
                                <div class="card-body p-4">
                                    <div class="badge bg-primary bg-gradient rounded-pill mb-2">ENGLISH</div>
                                    <a class="text-decoration-none link-dark stretched-link" href="#!"><h5 class="card-title mb-3">GENERAL ENGLISH</h5></a>
                                    <p class="card-text mb-0 tamil-font-dark-sm">இந்த புத்தகம் அனைத்து TNPSC குரூப் தேர்வுகளுக்கு உகந்தது</p>
                                    
                                </div>                                
                            </div>
                        </div>
                        <div class="col-lg-4 mb-5">
                            <div class="card h-100 shadow border-0">
                                <img class="card-img-top" src="@/assets/book-2.jpg" alt="..." />
                                <div class="card-body p-4">
                                    <div class="badge bg-primary bg-gradient rounded-pill mb-2">ENGLISH</div>
                                    <a class="text-decoration-none link-dark stretched-link" href="#!"><h5 class="card-title mb-3">MATHS-2 GENERAL MENTAL ABILITY APTITUDE TNPSC, UPSC, POLICE, SI, SSC etc</h5></a>
                                    <p class="card-text mb-0">This Books covers the syllabus of TNPSC GROUP-1-2-4 and all TNPSC Exams in English Medium. Vol.1 also available. Useful for Both GROUP-1 Prelims and Mains. Tamil Medium Books also available.</p>
                                </div>
                                
                            </div>
                        </div>
                        <div class="col-lg-4 mb-5">
                            <div class="card h-100 shadow border-0">
                                <img class="card-img-top" src="@/assets/book-3.jpg" alt="..." />
                                <div class="card-body p-4">
                                    <div class="badge bg-primary bg-gradient rounded-pill mb-2">News</div>
                                    <a class="text-decoration-none link-dark stretched-link" href="#!"><h5 class="card-title mb-3 tamil-font-dark-sm">கணிதம்-2 போட்டித் தேர்வு கணிதம்</h5></a>
                                    <p class="card-text mb-0">இந்த புத்தகம் அனைத்து TNPSC குரூப் தேர்வுகளுக்கு உகந்தது. தொகுதி-1 உம் உள்ளது. GROUP-1 முதனிலை மற்றும் முதன்மைத் தேர்வுக்கு உகந்தது. English Medium Books also available. கணிதப் புத்தகம் - 1 ODD PAGES ONLY கணிதப் புத்தகம் - 2 ODD PAGES ONLY</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                   
                </div>
  </section>
 

</template>
<script>
export default {
  name: 'BannerMain',
  props: {
    msg: String
  },
  data(){
    return {
        isVisible:false,
            }
  },
  methods: {
    toggleBox(){
        this.isVisible =!this.isVisible
    }

  },
  
  }
    

</script>
<style>
.bg-champ{
    background-color: #F7E7CE;
}
.btn-main{
    background-color: #005437;    
    text-decoration: none;
}
.btn-main:hover{
    background-color: #91DDCF;    
    text-decoration: none;
}
.tamil-font-dark-sm{
    font-family: "Noto Sans Tamil", sans-serif;
    font-weight: normal;
    color: #340744;
}
.tamil-font-light-sm{
    font-family: "Noto Sans Tamil", sans-serif;
    font-weight: normal;
    color: #DEBAD6;
}
.banner-head {
      font-family: "Noto Sans Tamil", sans-serif;
      font-optical-sizing: auto;
      font-weight: 600;
      font-style: normal;
      color: #005437;
      text-decoration: none;      
      }
.banner-para {
      font-family: "Noto Sans Tamil", sans-serif;
      font-optical-sizing: auto;
      font-weight: 600;
      font-style: normal;
      color: #340744;
      text-decoration: none;
      font-size: medium;

}
.typingEffect {
    width: 0;
    overflow: hidden; /* Ensure the text is not visible until the typewriter effect*/
    border-right: 2px solid white; /* The cursor*/    
    white-space: nowrap; /* Keeps the text on a single line */
    animation:typing 3s forwards infinite;
  }
.pricing-header {
  max-width: 700px;
}

.card-deck .card {
  min-width: 220px;
}

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }
.feature {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 5rem;
    width: 5rem;
   
}

/* The typing animation */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

</style>

